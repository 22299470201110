import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import PDFImageShow from "../../../web/src/images/PDF_file_icon.svg.png";

const SpecialistRegistrationspecialistdetails: React.FC = () => {
  const [specialistDetails, setSpecialistDetails] = useState({
    education: "",
    professionalTitle: "",
    yrsOfExp: "",
    gender: "",
    language: "",
    areaOfExp: "",
    email: "",
    phone: "",
    name: "",
    bio: "",
    igHandle: "",
  });
  const [fileResponse, setFileResponse] = useState<any>("");
  const [educationValidation, setEducationValidation] = useState<string>("");
  const [fileValidation, setFileValidation] = useState<any>("");
  const [professionalTitleValidation, setprofessionalTitleValidation] =
    useState<any>("");
  const [yrsOfExpValidation, setYrsOfExpValidation] = useState<any>("");
  const [genderValidation, setGenderValidation] = useState<any>("");
  const [languagepValidation, setLanguageValidation] = useState<any>("");
  const [areaOfExpValidation, setAreaOfExpValidation] = useState<any>("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState<any>("");
  const fileInputRef2 = useRef<HTMLInputElement>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [langaugeOptions, setLanguageOptions] = useState<any>([]);
  const [specialistDetails2, setSpecialistDetails2] = useState({
    email: "",
    phone: "",
    name: "",
    bio: "",
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imgSrc, setImgSrc] = useState<string>("");
  const [pdfResponse, setPdfResponse] = useState<any>("");
  const [imgValidation, setImgValidation] = useState<any>("");
  const [emailValidation, setEmailValidation] = useState<any>("");
  const [phoneValidation, setPhoneValidation] = useState<any>("");
  const [nameValidation, setNameValidation] = useState<any>("");
  const [bioValidation, setBioValidation] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);
  const [showPopup, setShowPopUp] = useState<any>("hide");
  const [hideModal, setHideModal] = useState<any>(false);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [igValidation, setIgValidation] = useState<any>("");
  const [areaOfExpOption, setAreaOfExpOption] = useState<any>("");
  const [specialistPuckData, setSpecialistPuckData] = useState<any>([]);
  const [customOption, setCustomOption] = useState<any>("");
  const specialistTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getCategories = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "categoryLists",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCategoryList(result?.data?.data);
        } else {
          setCategoryList([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const getSpecialistPuckData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "SPECIALIST FORM",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setSpecialistPuckData(data);
        } else {
          setSpecialistPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const specialistData = specialistPuckData?.content?.find(
    (elem: any) => elem.type === "SpecialistMedicalDetails"
  );

  const specialistPersonalData = specialistPuckData?.content?.find(
    (elem: any) => elem.type === "SpecialistPersonalDetails"
  );
  useEffect(() => {
    getSpecialistPuckData();
    getCategories();
  }, []);

  const handleSpecialistDetails = (e: any) => {
    const { name, value } = e.target;

    setSpecialistDetails({ ...specialistDetails, [name]: value });

    if (name === "education" && value !== "") {
      setEducationValidation("");
    }
    if (name === "professionalTitle" && value !== "") {
      setprofessionalTitleValidation("");
    }

    if (name === "yrsOfExp" && value !== "") {
      setYrsOfExpValidation("");
    }

    if (name === "gender" && value !== "") {
      setGenderValidation("");
    }

    if (name === "areaOfExp" && value !== "") {
      setAreaOfExpValidation("");
    }

    if (name === "igHandle" && value !== "") {
      setIgValidation("");
    }
  };

  const handleKeyDownEvent = async (e: any) => {
    if (e.keyCode === 32 && e.target.value.trim() === "") {
      e.preventDefault();
    }
  };

  const handleTriggerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click();
      setFileValidation([]);
    }
  };

  const handleFileChange = async (e: any) => {
    const selectedFile = e.target.files[0];
    const maxSizeInBytes = 50 * 1024 * 1024; // 50 MB

    const allowedTypes = ["application/pdf"];

    if (
      allowedTypes.includes(selectedFile.type) &&
      selectedFile.size < maxSizeInBytes
    ) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
    if (
      allowedTypes.includes(selectedFile.type) &&
      selectedFile.size > maxSizeInBytes
    ) {
      return toast.error(
        "Selected file exceeds the maximum size limit (50 MB)"
      );
    }
    if (!allowedTypes.includes(selectedFile.type)) {
      setFile(null);
      setFileName("");
      toast.error("Please select a pdf file");
      return;
    }
  };

  const cancelSelectedFile = () => {
    setFile(null);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChange = (selectedOption: any) => {
    if (selectedOption !== "") {
      setSpecialistDetails({
        ...specialistDetails,
        language: selectedOption.map((elem: any) => elem.label),
      });
      setLanguageValidation("");
    }
    setSelectedOption(selectedOption);
  };

  const handleAreaOfExp = (selectedOption: any) => {
    if (selectedOption && selectedOption.length > 0) {
      const labels = selectedOption.map((elem: any) => elem.label);

      setSpecialistDetails({
        ...specialistDetails,
        areaOfExp: labels,
      });

      setAreaOfExpValidation("");

      if (labels.includes("Other")) {
        // Logic to handle the appearance of the custom input field
        setCustomOption(""); // Clear the custom input field when "Other" is selected
      }
    }
    setAreaOfExpOption(selectedOption);
  };

  const cancelSelectedFile2 = () => {
    setSelectedFile(null);
    setImgSrc("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleKeyDownEvent2 = async (e: any) => {
    if (e.keyCode === 32 && e.target.value.trim() === "") {
      e.preventDefault();
    }
  };

  const handleNumericKeydownEvent = async (e: any) => {
    if (
      !(
        // Allow control keys
        (
          e.key === "Backspace" ||
          e.key === "Delete" ||
          // Allow numeric keys
          (e.key >= "0" && e.key <= "9") ||
          // Allow numeric keypad keys
          (e.key >= "Num0" && e.key <= "Num9") ||
          // Allow arrow keys
          e.key.includes("Arrow")
        )
      )
    ) {
      e.preventDefault();
    }
  };

  const handleSpecialistDetails2 = (e: any) => {
    setShowPopUp("hide");
    const { name, value } = e.target;
    setSpecialistDetails2({ ...specialistDetails2, [name]: value });
    if (name === "email" && value !== "") {
      setEmailValidation("");
    }
    if (name === "name" && value !== "") {
      setNameValidation("");
    }
    if (name === "phone" && value !== "") {
      setPhoneValidation("");
    }
    if (name === "bio" && value !== "") {
      setBioValidation("");
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTriggerClick2 = () => {
    if (fileInputRef2.current) {
      fileInputRef2.current?.click();
      setImgValidation("");
    }
  };

  const handleFileChange2 = async (e: any) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        const imageSrc = URL.createObjectURL(file);
        setImgSrc(imageSrc);
        await fileUpload(file);
      } else {
        toast.error("Please select a PNG, JPG, or JPEG file.");
        setSelectedFile(null);
        setImgSrc("");
        e.target.files = null;
      }
    }
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, formData, {
        headers: {
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXVpZCI6ImRhZjc5ZmEyLWQzNWMtNGZiZS1hZDVkLTMwY2ZjOTFiNzY5MyIsImVtYWlsIjoic3VwZXJhZG1pbkBzZWxmbWF4aW1pemVkLmNvbSIsInJvbGUiOiJhZG1pbiIsIm9uQ3JlYXRlIjoiMjAyNC0wMi0wOFQwODo1MzozOC4wMDBaIiwib25VcGRhdGUiOiIyMDI0LTAyLTA4VDA4OjUzOjM4LjAwMFoiLCJpYXQiOjE3MDczODI2OTYsImV4cCI6MTcwNzM5MTY5Nn0.RtpLrloMLDoWxzEMSPLwssPr53U5GsiEYj0kHK2Hz64",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result?.data?.data?.url) {
          setFileResponse(result?.data?.data?.url);
        } else {
          return;
        }
      });
  };

  const pdfUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, formData, {
        headers: {
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwidXVpZCI6ImRhZjc5ZmEyLWQzNWMtNGZiZS1hZDVkLTMwY2ZjOTFiNzY5MyIsImVtYWlsIjoic3VwZXJhZG1pbkBzZWxmbWF4aW1pemVkLmNvbSIsInJvbGUiOiJhZG1pbiIsIm9uQ3JlYXRlIjoiMjAyNC0wMi0wOFQwODo1MzozOC4wMDBaIiwib25VcGRhdGUiOiIyMDI0LTAyLTA4VDA4OjUzOjM4LjAwMFoiLCJpYXQiOjE3MDczODI2OTYsImV4cCI6MTcwNzM5MTY5Nn0.RtpLrloMLDoWxzEMSPLwssPr53U5GsiEYj0kHK2Hz64",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result?.data?.data?.url) {
          setPdfResponse(result?.data?.data?.url);
        } else {
          return;
        }
      });
  };
  const yrsOfExpRef = useRef<HTMLSelectElement>(null);
  const genderRef = useRef<HTMLSelectElement>(null);
  const areaOfExpRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {});

  const createSpecialist = async () => {
    setShowPopUp("hide");
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let contactRegex = /^[0-9]{0,13}$/;
    const regex = /[A-Za-z\s]+/;
    const isNameValid = regex.test(specialistDetails2.name);

    let isPhoneValid = contactRegex.test(specialistDetails2.phone);
    let isEmailValid = emailRegex.test(specialistDetails2.email);

    if (
      !specialistDetails2.email ||
      !specialistDetails2.phone ||
      !specialistDetails2.name ||
      !isNameValid ||
      !specialistDetails2.bio ||
      !specialistDetails.igHandle ||
      !isPhoneValid ||
      !isEmailValid ||
      !imgSrc ||
      !specialistDetails.education ||
      !specialistDetails.professionalTitle ||
      !specialistDetails.yrsOfExp ||
      !specialistDetails.gender ||
      !specialistDetails.language ||
      !specialistDetails.areaOfExp ||
      !fileName
    ) {
      if (!specialistDetails2.email) {
        setEmailValidation("Please enter email");
      } else if (!isEmailValid) {
        setEmailValidation("Invalid email format");
      } else {
        setEmailValidation("");
      }

      if (!imgSrc) {
        setImgValidation("Please add image");
      } else {
        setImgValidation("");
      }

      if (!specialistDetails2.phone) {
        setPhoneValidation("Please enter phone no");
      } else if (!isPhoneValid) {
        setPhoneValidation("Invalid contact number format.");
      } else {
        setPhoneValidation("");
      }

      if (!specialistDetails2.name) {
        setNameValidation("Please enter name");
      } else if (!isNameValid) {
        setNameValidation("Please enter only text");
      } else {
        setNameValidation("");
      }

      if (!specialistDetails2.bio) {
        setBioValidation("Please enter bio");
      } else {
        setBioValidation("");
      }

      if (!specialistDetails.education) {
        setEducationValidation("Please enter education");
      } else {
        setEducationValidation("");
      }

      if (!fileName) {
        setFileValidation("Please select file");
      } else {
        setFileValidation("");
      }

      if (!specialistDetails.professionalTitle) {
        setprofessionalTitleValidation("Please enter professional title");
      } else {
        setprofessionalTitleValidation("");
      }

      if (!specialistDetails.yrsOfExp) {
        setYrsOfExpValidation("Please enter years of experience");
      } else {
        setYrsOfExpValidation("");
      }

      if (!specialistDetails.gender) {
        setGenderValidation("Please enter gender");
      } else {
        setGenderValidation("");
      }

      if (!selectedOption) {
        setLanguageValidation("Please enter language");
      } else {
        setLanguageValidation("");
      }

      if (!specialistDetails.igHandle) {
        setIgValidation("Please enter your instagram username");
      } else {
        setIgValidation("");
      }

      if (!specialistDetails.areaOfExp) {
        setAreaOfExpValidation("Please enter area of expertise");
      } else {
        setAreaOfExpValidation("");
      }

      return;
    }
    setLoader(true);
    if (selectedFile && file) {
      await Promise.all([pdfUpload(file), fileUpload(selectedFile)]);
    }

    let arrayOfExp: { category: string; other: boolean }[] = [];

    if (typeof specialistDetails.areaOfExp === "string") {
      try {
        const parsedAreaOfExp = JSON.parse(specialistDetails.areaOfExp);
        arrayOfExp = parsedAreaOfExp.map((elem: any) => {
          return [
            {
              category: elem,
              other: parsedAreaOfExp === "OTHER",
            },
          ];
        });
      } catch (error) {
        console.error("Invalid JSON string in areaOfExp:", error);
        arrayOfExp = [];
      }
    } else if (Array.isArray(specialistDetails.areaOfExp)) {
      arrayOfExp = (specialistDetails.areaOfExp as string[]).map((elem) => {
        return {
          category: elem,
          other: elem === "OTHER",
        };
      });
    } else {
      console.error("areaOfExp is neither a string nor an array.");
    }

    let isOtherValuePresent: any = arrayOfExp.some(
      (elem: any) => elem === "OTHER"
    );

    console.log(specialistDetails.language);
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        professional: {
          education: `${specialistDetails.education}`,
          professionalTitle: `${specialistDetails.professionalTitle}`,
          yearOfExprience: Number(specialistDetails.yrsOfExp),
          language: specialistDetails.language,
          areaOfExpertise: sortOptions,
          gender: `${specialistDetails.gender}`,
          documentUrl: `${pdfResponse}`,
        },
        email: `${specialistDetails2.email}`,
        name: `${specialistDetails2.name}`,
        phone: `${specialistDetails2.phone}`,
        gender: `${specialistDetails.gender}`,
        bio: `${specialistDetails2.bio}`,
        igHandle: `${specialistDetails.igHandle}`,
        orgnizationId: 1,
        dob: "",
        role: "specialist",
        tags: "doctor,NSS,Test",
        profileimage: `${fileResponse}`,
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
        baseLink: `${process.env.REACT_APP_LOCALHOST_URL_APP}#`,
        timezone: specialistTimeZone,
        action: "command",
        command: [
          {
            agent: "createSpecialist",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.status === "success") {
          setLoader(false);
          setShowPopUp("show");
          setTimeout(() => {
            setShowPopUp("hide");
          }, 1000);
          setTimeout(() => {
            setHideModal(true);
          }, 1500);
          setSelectedOption("");
          setAreaOfExpOption("");

          if (yrsOfExpRef.current) {
            yrsOfExpRef.current.value = "";
          }
          if (genderRef.current) {
            genderRef.current.value = "";
          }
          if (areaOfExpRef.current) {
            areaOfExpRef.current.value = "";
          }
          if (fileInputRef2.current) {
            fileInputRef2.current.value = "";
          }
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setFileName("");
          setSpecialistDetails({
            education: "",
            professionalTitle: "",
            yrsOfExp: "",
            gender: "",
            language: "",
            areaOfExp: "",
            email: "",
            phone: "",
            name: "",
            bio: "",
            igHandle: "",
          });
          setSpecialistDetails2({ email: "", phone: "", name: "", bio: "" });
          setImgSrc("");
        }
        if (result?.data?.status === "failed") {
          toast.error(
            "User with this email id is already resgistered with us."
          );
          setLoader(false);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        return toast.info(err.response.data.message);
      });
  };

  useEffect(() => {
    if (file) {
      pdfUpload(file);
    }
  }, [file]);

  useEffect(() => {
    if (showPopup === "show") {
      const openModalButton = document.querySelector(
        "#openModal"
      ) as HTMLButtonElement | null;
      if (openModalButton) {
        openModalButton.click();
      }
    }
  }, [showPopup === "show"]);

  const getLanguagesList = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        action: "command",
        command: [
          {
            agent: "languageLists",
            appName: "selfMaximized",
            folder: "build",
          },
        ],
      })
      .then((result) => {
        setLanguageOptions(result?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLanguagesList();
  }, []);

  const mappedOptions = langaugeOptions
    .sort((a: any, b: any) => a.language.localeCompare(b.language))
    .map((option: any) => ({
      value: option.id,
      label: option.language,
    }));

  const mappedAreaOfExpOptions =
    categoryList && categoryList.length !== 0
      ? [
          ...categoryList.map((option: any, index: any) => ({
            value: index,
            label: option.name,
          })),
          { value: "OTHER", label: "OTHER" }, // Adding the "Other" option
        ]
      : [{ value: 1, label: "ENGLISH" }];

  const isOtherSelected =
    areaOfExpOption && areaOfExpOption.length > 0
      ? areaOfExpOption.find((elem: any) => elem.value === "OTHER")
      : false;

  const handleCustomInputChange = (e: any) => {
    const customValue = e.target.value;
    setCustomOption(customValue);

    // Update the specialistDetails with the custom value
    setSpecialistDetails((prevDetails: any) => ({
      ...prevDetails,
      areaOfExp: prevDetails.areaOfExp.map((label: string) =>
        label === "OTHER" ? customValue : label
      ),
    }));
  };

  const sortOptions =
    areaOfExpOption && areaOfExpOption.length !== 0
      ? areaOfExpOption.map((elem: any) => {
          return {
            category:
              elem.label !== "OTHER" ? elem.label : customOption.toUpperCase(),
            other: elem.label === "OTHER",
          };
        })
      : [];

  const getCategoriesList = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "isActive",
                operator: "eq",
                value: 1,
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "categoryLists",
            appName: "selfMaximized",
            folder: "category",
          },
          {
            agent: "categoryDoctorList",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCategoryList(result.data?.data);
        } else {
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  return (
    <>
      <section className="user-registration-flow-wrapper pb-0">
        <div className="container-fluid left-right-space">
          <div className="row justify-content-center text-center py-4">
            <div className="col-12 col-lg-10 col-xl-8">
              <h1 className="user-registration-title mb-0">
                {specialistData?.props?.registrationTitle
                  ? specialistData?.props?.registrationTitle
                  : "BASIC REGISTER PROCESS"}
              </h1>
            </div>
          </div>
          <div className="row userregistration-wrapper-bg">
            <div className="col-lg-12 py-2">
              <h2 className="lets-fill-basic mb-0 pb-3 pb-lg-0">
                {specialistData?.props?.communityOfSpecialists
                  ? specialistData?.props?.communityOfSpecialists
                  : "JOIN THE SELF MAXIMIZED COMMUNITY OF SPECIALISTS"}
              </h2>
              <p className="mb-0 before-embarking pb-2">
                {specialistData?.props?.registrationSummary
                  ? specialistData?.props?.registrationSummary
                  : `BEFORE EMBARKING ON OUR JOURNEY TOGETHER, KINDLY ANSWER A FEW
                ONBOARDING QUESTIONS IN JUST TWO QUICK STEPS.`}
              </p>
              <p className="mb-personal-details">
                {specialistData?.props?.backgroundTitle
                  ? specialistData?.props?.backgroundTitle
                  : "BACKGROUND"}
              </p>
              <form className="pt-5">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="d-flex justify-content-between">
                      <label
                        htmlFor="update-pdf-file"
                        className="update-pdf-file"
                      >
                        *
                        {specialistData?.props?.pdfProfile
                          ? specialistData?.props?.pdfProfile
                          : "UPLOAD PDF PROFILE"}
                      </label>
                      <span
                        className="cancel-icon"
                        onClick={cancelSelectedFile}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                    <div className="col-ting_pdf" onClick={handleTriggerClick}>
                      <div className="drop-zone">
                        {fileName ? (
                          <div>
                            <img
                              src={PDFImageShow}
                              alt=""
                              className="img-fluid w-100 pdf-image-show"
                              style={{ width: "auto", height: "auto" }}
                              loading="lazy"
                            />
                            <div>
                              <label>{fileName}</label>
                            </div>
                          </div>
                        ) : (
                          <p className="mb-0 upload__image__user">
                            UPLOAD PDF FILE
                          </p>
                        )}
                        <input
                          type="file"
                          ref={fileInputRef}
                          name="myFile"
                          style={{ display: "none" }}
                          accept=".pdf"
                          onChange={handleFileChange}
                          autoComplete="off"
                          id="input_file"
                        />
                      </div>
                      <span className="text-danger registration__error--msg text-uppercase mt-2">
                        {fileValidation}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-12 pb-3">
                        <label htmlFor="education">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          {specialistData?.props?.education
                            ? specialistData?.props?.education
                            : "EDUCATION"}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="education"
                          value={specialistDetails.education}
                          onChange={handleSpecialistDetails}
                          autoComplete="off"
                          onKeyDown={handleKeyDownEvent}
                        />
                        <span className="text-danger registration__error--msg text-uppercase d-block">
                          {educationValidation}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="ptitle">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          {specialistData?.props?.professionalTitle
                            ? specialistData?.props?.professionalTitle
                            : "PROFESSIONAL TITLE"}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="professionalTitle"
                          value={specialistDetails.professionalTitle}
                          onChange={handleSpecialistDetails}
                          autoComplete="off"
                          onKeyDown={handleKeyDownEvent}
                        />
                        <span className="text-danger registration__error--msg text-uppercase">
                          {professionalTitleValidation}
                        </span>
                      </div>
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="yexperience">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          {specialistData?.props?.yearOfExperience
                            ? specialistData?.props?.yearOfExperience
                            : "YEARS OF EXPERIENCE"}
                        </label>
                        <div id="selfDropdown">
                          <select
                            id="select"
                            ref={yrsOfExpRef}
                            name="yrsOfExp"
                            onChange={handleSpecialistDetails}
                            onKeyDown={handleKeyDownEvent}
                            className="letter_spacing"
                          >
                            <option value="" disabled selected>
                              SELECT EXPERIENCE
                            </option>
                            <option value="5">LESS THAN 5</option>
                            <option value="5">OVER 5</option>
                            <option value="10">OVER 10</option>
                            <option value="15">OVER 15</option>
                          </select>
                        </div>
                        <span className="text-danger registration__error--msg text-uppercase">
                          {yrsOfExpValidation}
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="gender">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          {specialistData?.props?.gender
                            ? specialistData?.props?.gender
                            : "GENDER"}
                        </label>

                        <div id="selfDropdown">
                          <select
                            id="select"
                            ref={genderRef}
                            onChange={handleSpecialistDetails}
                            name="gender"
                            onKeyDown={handleKeyDownEvent}
                          >
                            <option value="" disabled selected>
                              SELECT GENDER
                            </option>
                            <option value="male">MALE</option>
                            <option value="female"> FEMALE</option>
                          </select>
                        </div>
                        <span className="text-danger registration__error--msg text-uppercase">
                          {genderValidation}
                        </span>
                      </div>
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="language">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          {specialistData?.props?.languages
                            ? specialistData?.props?.languages
                            : "LANGUAGES"}
                        </label>
                        <div id="selfDropdown" className="language__wrapper">
                          <Select
                            value={selectedOption}
                            onChange={handleChange}
                            options={mappedOptions}
                            isSearchable={true}
                            name="language"
                            className="text-dark text-uppercase letter-spacing"
                            isMulti
                            styles={{
                              control: (provided: any, state: any) => ({
                                ...provided,
                                fontSize: "16px",
                                color: "inherit",
                                backgroundColor: "#fff",
                                letterSpacing: "1px",
                                border: state.isFocused
                                  ? "2px solid black"
                                  : "2px solid transparent", // Custom border style
                                boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow
                                "&:hover": {
                                  borderColor: "black", // Border color on hover
                                },
                              }),
                              option: (provided: any, state: any) => ({
                                ...provided,
                                color:
                                  state.isFocused || state.isSelected
                                    ? "white"
                                    : "black", // Change color to black when focused or selected
                                backgroundColor: state.isFocused
                                  ? "#000"
                                  : "#fff", // Background color when focused
                              }),
                              singleValue: (provided: any) => ({
                                ...provided,
                                color: "#000", // Set color of the selected option to white
                              }),
                              input: (provided: any) => ({
                                ...provided,
                                color: "#000",
                              }),
                              placeholder: (provided: any) => ({
                                ...provided,
                                color: "black",
                              }),
                              menu: (provided: any) => ({
                                ...provided,
                                backgroundColor: "#000",
                              }),
                            }}
                            placeholder="SEARCH..."
                          />
                        </div>
                        <span className="text-danger registration__error--msg text-uppercase">
                          {languagepValidation}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="aexpertise">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          {specialistData?.props?.areaOfExpertise
                            ? specialistData?.props?.areaOfExpertise
                            : "AREA OF EXPERTISE"}
                        </label>
                        <div id="selfDropdown">
                          <Select
                            value={areaOfExpOption}
                            onChange={handleAreaOfExp}
                            options={mappedAreaOfExpOptions}
                            isSearchable={true}
                            name="areaOfExp"
                            className="text-dark letter_spacing"
                            isMulti
                            styles={{
                              control: (provided: any, state: any) => ({
                                ...provided,
                                fontSize: "16px",
                                color: "inherit",
                                backgroundColor: "#fff",
                                letterSpacing: "1px",
                                border: state.isFocused
                                  ? "2px solid black"
                                  : "2px solid transparent", // Custom border style
                                boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow
                                "&:hover": {
                                  borderColor: "black", // Border color on hover
                                },
                              }),
                              option: (provided: any, state: any) => ({
                                ...provided,
                                color:
                                  state.isFocused || state.isSelected
                                    ? "white"
                                    : "black", // Change color to black when focused or selected
                                backgroundColor: state.isFocused
                                  ? "#000"
                                  : "#fff", // Background color when focused
                              }),
                              singleValue: (provided: any) => ({
                                ...provided,
                                color: "#000", // Set color of the selected option to white
                              }),
                              input: (provided: any) => ({
                                ...provided,
                                color: "black",
                              }),
                              placeholder: (provided: any) => ({
                                ...provided,
                                color: "white",
                              }),
                              menu: (provided: any) => ({
                                ...provided,
                                backgroundColor: "#000",
                              }),
                            }}
                            placeholder="SEARCH..."
                          />
                          {isOtherSelected && (
                            <input
                              type="text"
                              value={customOption}
                              className="form-control mt-3 text-dark custom-input"
                              onChange={handleCustomInputChange}
                              placeholder="ENTER YOUR EXPERTISE"
                            />
                          )}
                        </div>
                        <span className="text-danger registration__error--msg text-uppercase">
                          {areaOfExpValidation}
                        </span>
                      </div>
                      <div className="col-lg-6 pb-3">
                        <label htmlFor="igHandle">
                          <span className="text-white pe-1 vertical-top">
                            *
                          </span>
                          {specialistData?.props?.instagramHandle
                            ? specialistData?.props?.instagramHandle
                            : "INSTAGRAM HANDLE"}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          name="igHandle"
                          value={specialistDetails.igHandle}
                          onChange={handleSpecialistDetails}
                          autoComplete="off"
                          onKeyDown={handleKeyDownEvent}
                        />
                        <span className="text-danger registration__error--msg text-uppercase text-uppercase">
                          {igValidation}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <section className="pt-0 pb-0">
                <div className="container-fluid px-0">
                  <div className="row pt-5 mt-5">
                    <div className="col-lg-12">
                      <h2 className="lets-fill-basic mb-0 pb-3 pb-lg-0">
                        {specialistPersonalData?.props
                          ?.specialistPersonaldetailstitle
                          ? specialistPersonalData?.props
                              ?.specialistPersonaldetailstitle
                          : "LETS FILL PERSONAL DETAILS"}
                      </h2>
                      <p className="mb-0 before-embarking pb-2">
                        {specialistPersonalData?.props?.specialistInstructions
                          ? specialistPersonalData?.props
                              ?.specialistInstructions
                          : "BEFORE EMBARKING ON OUR JOURNEY TOGETHER, KINDLY ANSWER A FEW ONBOARDING QUESTIONS IN JUST TWO QUICK STEPS."}
                      </p>
                      <p className="mb-personal-details">
                        {specialistPersonalData?.props?.specialistProfileTitle
                          ? specialistPersonalData?.props
                              ?.specialistProfileTitle
                          : "PROFILE"}
                      </p>
                      <form className="pt-5">
                        <div className="row">
                          <div className="col-lg-2">
                            <div className="d-flex justify-content-between">
                              <label
                                htmlFor="update-pdf-file"
                                className="update-pdf-file"
                              >
                                <span className="text-white pe-1 vertical-top">
                                  *
                                </span>
                                {specialistPersonalData?.props?.profilePic
                                  ? specialistPersonalData?.props?.profilePic
                                  : "UPLOAD PROFILE PIC"}
                              </label>
                              <span
                                className="cancel-icon"
                                onClick={cancelSelectedFile2}
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </span>
                            </div>

                            <div
                              className="col-ting pb-2"
                              onClick={handleTriggerClick2}
                            >
                              <div
                                className="control-group file-upload"
                                id="file-upload1"
                              >
                                <div
                                  className="text-center d-flex justify-content-center align-items-center file__upload--inner"
                                  style={{
                                    display: "block",
                                    margin: "0 auto",
                                    height: "10em",
                                    backgroundColor: "#fff",
                                    color: "#fff",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                  }}
                                >
                                  {imgSrc ? (
                                    <img
                                      className="specialist__user__image img-fluid w-100 h-100"
                                      src={imgSrc}
                                      alt=""
                                    />
                                  ) : (
                                    <p className="mb-0 upload__image__user">
                                      UPLOAD IMAGE
                                    </p>
                                  )}
                                </div>
                                <div
                                  className="controls"
                                  style={{ display: "none" }}
                                >
                                  <input
                                    type="file"
                                    ref={fileInputRef2}
                                    name="contact_image_1"
                                    onChange={handleFileChange2}
                                    autoComplete="off"
                                    accept=".png, .jpg, .jpeg"
                                  />
                                </div>
                              </div>
                              <span className="text-danger please__add--image text-uppercase text-uppercase">
                                {imgValidation}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-10">
                            <div className="row">
                              <div className="col-lg-6 pb-3">
                                <label htmlFor="name">
                                  <span className="text-white pe-1 vertical-top">
                                    *
                                  </span>
                                  {specialistPersonalData?.props?.specialistName
                                    ? specialistPersonalData?.props
                                        ?.specialistName
                                    : "NAME"}
                                </label>
                                <input
                                  type="text"
                                  pattern="[A-Za-z\s]+"
                                  className="form-control"
                                  placeholder=""
                                  name="name"
                                  value={specialistDetails2.name}
                                  onChange={handleSpecialistDetails2}
                                  autoComplete="off"
                                  onKeyDown={handleKeyDownEvent2}
                                />
                                <span className="text-danger  registration__error--msg text-uppercase">
                                  {nameValidation}
                                </span>
                              </div>
                              <div className="col-lg-6 pb-3">
                                <label htmlFor="email">
                                  <span className="text-white pe-1 vertical-top">
                                    *
                                  </span>
                                  {specialistPersonalData?.props
                                    ?.specialistEmail
                                    ? specialistPersonalData?.props
                                        ?.specialistEmail
                                    : "EMAIL"}
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder=""
                                  name="email"
                                  value={specialistDetails2.email}
                                  onChange={handleSpecialistDetails2}
                                  autoComplete="off"
                                  onKeyDown={handleKeyDownEvent2}
                                />
                                <span className="text-danger  registration__error--msg text-uppercase">
                                  {emailValidation}
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 pb-3">
                                <label htmlFor="cnumber">
                                  <span className="text-white pe-1 vertical-top">
                                    *
                                  </span>
                                  {specialistPersonalData?.props
                                    ?.specialistContactNumber
                                    ? specialistPersonalData?.props
                                        ?.specialistContactNumber
                                    : "CONTACT NUMBER"}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  name="phone"
                                  value={specialistDetails2.phone}
                                  onChange={handleSpecialistDetails2}
                                  autoComplete="off"
                                  onKeyDown={handleNumericKeydownEvent}
                                />
                                <span className="text-danger  registration__error--msg text-uppercase">
                                  {phoneValidation}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 pb-3">
                            <label htmlFor="comment">
                              <span className="text-white pe-1 vertical-top">
                                *
                              </span>
                              {specialistPersonalData?.props?.specialistBio
                                ? specialistPersonalData?.props?.specialistBio
                                : "BIO"}
                            </label>
                            <textarea
                              className="form-control"
                              rows={10}
                              cols={7}
                              id="comment"
                              name="bio"
                              value={specialistDetails2.bio}
                              onChange={handleSpecialistDetails2}
                              onKeyDown={handleKeyDownEvent2}
                            ></textarea>
                            <span className="text-danger  registration__error--msg text-uppercase">
                              {bioValidation}
                            </span>
                          </div>
                        </div>
                        <div className="row pb-3">
                          <div className="col-lg-12 text-end position-relative">
                            {loader ? (
                              <div className="ml-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ) : null}

                            {showPopup === "show" ? (
                              <button
                                className="next-btn"
                                type="button"
                                id="openModal"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                <span className="">
                                  {" "}
                                  {specialistPersonalData?.props?.submit
                                    ? specialistPersonalData?.props?.submit
                                    : "SUBMIT"}
                                </span>
                              </button>
                            ) : (
                              showPopup !== "show" &&
                              !loader && (
                                <button
                                  className="next-btn"
                                  type="button"
                                  onClick={createSpecialist}
                                >
                                  <span className="">
                                    {" "}
                                    {specialistPersonalData?.props?.submit
                                      ? specialistPersonalData?.props?.submit
                                      : "SUBMIT"}
                                  </span>
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                    <div
                      className="modal fade p-0 user__pop__wrapper"
                      id="exampleModal"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content user_response--popup py-5 position-relative">
                          <div className="modal-body text-center my-5 py-5">
                            <img
                              src="/images/user-registraion/login-logo.png"
                              alt="user-logo"
                              className="img-fluid"
                              style={{ width: "150px", height: "150px" }}
                              loading="lazy"
                            />
                            <h3 className="mb-0 thanks-for-your-response">
                              THANKS FOR YOUR RESPONSE
                            </h3>
                            <p className="mb-0 you-will-receive">
                              "You Will Receive A Confirmation Via Email.
                            </p>
                          </div>
                          <div
                            className="cancel__pop__icon"
                            data-bs-dismiss="modal"
                          >
                            <i className="fa-solid fa-xmark text-white"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              className="modal fade"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center my-5 py-5">
                    <img
                      src="/images/user-registraion/login-logo.png"
                      alt="user-logo"
                      className="img-fluid pb-5"
                    />
                    <h3 className="mb-0 thanks-for-your-response pb-2">
                      THANKS FOR YOUR RESPONSE
                    </h3>
                    <p className="mb-0 you-will-receive">
                      "You Will Receive A Confirmation Via Email.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SpecialistRegistrationspecialistdetails;
