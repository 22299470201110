import React, { useContext } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import AuthContext from "../../../UseContext";
import { toast } from "react-toastify";

const SuccessPage: React.FC = () => {
  const goToDashboard = () => {
    const getLocalStorageItem = localStorage.getItem("userInfo");
    const userInfo =
      typeof getLocalStorageItem === "string"
        ? JSON.parse(getLocalStorageItem)
        : getLocalStorageItem;
    const lastActiveCart = userInfo ?? userInfo[0];
    if (lastActiveCart.lastActiveCart) {
      localStorage.clear();
    }
    window.location.href = "/app/#/dashboard";
  };

  const { id } = useParams();

  const authContext = useContext(AuthContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntent = urlParams.get("payment_intent");
    const paymentIntentClientSecret = urlParams.get(
      "payment_intent_client_secret"
    );

    const afterOrderSuccess = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            payment_intent: paymentIntent,
            payment_intent_client_secret: paymentIntentClientSecret,
            redirect_status: "succeeded",
            action: "command",
            command: [
              {
                agent: "orderSuccess",
                appName: "selfMaximized",
                folder: "order",
              },
            ],
          },
          {
            headers: { "x-access-token": localStorage.getItem("token") },
          }
        )
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            authContext.cart(0);
            localStorage.removeItem("cartId");
          }
        })
        .catch((err) => {
          return toast.error("Something went wrong", err);
        });
    };

    afterOrderSuccess();
  }, []);

  return (
    <div className="success-container">
      <div className="success-content position-relative p-5">
        <div>
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            {" "}
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />{" "}
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <h2 className="success-title mt-3 mb-0">Payment Successful!</h2>
        <p className="success-message mb-0">
          Thank you for your purchase. Your order has been successfully
          processed.
        </p>
        <button
          className="btn btn-light mt-2 go__to--dashboard"
          onClick={goToDashboard}
        >
          GO TO DASHBOARD
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;
